import { createTabs } from "@/components/Tabs/models";

export const tabs = createTabs(
  {
    name: "аналитика",
    image: {
      alt: "аналитика",
      view: "01",
      name: "01",
    },
    extraImage: {
      alt: "",
      view: "01",
      name: "01_extra",
    },
    subs: [
      {
        name: "Анализ объекта автоматизации",
        text:
          "Оценивается целесообразность разработки. " +
          "Для этого собираются данные об объекте автоматизации, " +
          "определяется общее качество работы объекта автоматизации " +
          "и осуществляемые им виды деятельности." +
          "\n\n" +
          "Выявляются проблемы, которые можно решить внедрением автоматизации. " +
          "Дается общая оценка ситуации, выявляются объем, сроки, " +
          "предварительная стоимость и область работы, " +
          "оценивается возможность её проведения.",
      },

      {
        name: "Формирование требований к автоматизированной системе (АС)",
        text:
          "Проводится углубленное изучение объекта автоматизации. " +
          "Определяются и фиксируются требования к системе, бизнес-требования заказчика." +
          "\n\n" +
          "Подготавливаются данные для формирования требований к системе. " +
          "Учитываются возможные ограничения на разработку, " +
          "определяются границы затрат и условия функционирования системы, " +
          "рассчитывается ожидаемый эффект от внедрения системы." +
          "\n\n" +
          "По результатам заказчику предоставляется Отчет о выполненных работах, " +
          "включающий заявку на разработку АС в соответствии со сформированными требованиями.",
      },

      {
        name: "Проведение научно-исследовательских работ",
        text:
          "Проведение научно-исследовательских работ (НИР) " +
          "с целью оценки возможности реализации требований пользователей к АС. " +
          "В процессе НИР проводится предварительное проектирование, " +
          "в процессе которого определяется возможность " +
          "и способы реализации особых требований заказчика" +
          "\n\n" +
          "Результаты исследований предоставляются заказчику в Отчете о НИР.",
      },

      {
        name: "Разработка концепции автоматизированной системы (АС)",
        text:
          "Разработка концепции АС проводится в случае необходимости проверки " +
          "и сопоставления нескольких вариантов возможной реализации." +
          "\n\n" +
          "Исходя из результатов проведенных исследований, " +
          "разрабатывается несколько вариантов концепций АС, " +
          "удовлетворяющих требованиям пользователей. " +
          "Проводится оценка вариантов по затратам " +
          "на реализацию и поддержание функционирования, " +
          "сравниваются преимущества и недостатки каждого решения " +
          "и выявляется наиболее эффективный вариант." +
          "\n\n" +
          "Концепции АС могут быть представлены заказчику " +
          "в виде Отчета о выполненных работах " +
          "или отдельного документа «Концепция АС».",
      },
    ],
  },

  {
    name: "проектирование",
    image: {
      alt: "проектирование",
      view: "01",
      name: "02",
    },
    extraImage: {
      alt: "",
      view: "01",
      name: "02_extra",
    },
    subs: [
      {
        name: "Разработка технического задания",
        text:
          "Техническое задание (ТЗ) – основной документ, " +
          "определяющий весь процесс создания системы и приемку ее заказчиком. " +
          "Содержит в себе требования к системе, этапы " +
          "и сроки разработки системы и документации на нее. " +
          "Задает порядок разработки, приемки " +
          "и перечень технических документов, необходимых для создания системы." +
          "\n\n" +
          "ТЗ может включать в себя требования " +
          "по разработке технических заданий на отдельные части АС, " +
          "программные и технические средства системы.",
      },

      {
        name: "Разработка эскизного проекта",
        text:
          "Эскизный проект - это пакет документации, " +
          "разрабатываемый перед техническим проектом или вместе с ним. " +
          "Цель создания этих документов – проектирование архитектуры системы, " +
          "определение операционной среды и требований к конфигурации оборудования системы." +
          "\n\n" +
          "Эскизный проект может и не разрабатываться, " +
          "если новые данные не предоставляются. " +
          "Необходимость разработки устанавливается техническим заданием.",
      },

      {
        name: "Разработка технического проекта",
        text:
          "Технический проект - это пакет документации, " +
          "который описывает окончательные решения по проектированию АС." +
          "\n\n" +
          "Технический проект описывает во всех аспектах решения по системе и ее частям, " +
          "функционально-алгоритмической структуре системы, " +
          "по функциям персонала и организационной структуре, " +
          "по структуре технических средств, алгоритмам решения задач и применяемым языкам, " +
          "по организации и ведению информационной базы, " +
          "системе классификации и кодирования информации, по программному обеспечению." +
          "\n\n" +
          "Технический проект может разрабатываться вместе с эскизным проектом или без него, " +
          "если проектные решения определены ранее или достаточно очевидны.",
      },
    ],
  },

  {
    name: "разработка",
    image: {
      alt: "разработка",
      view: "01",
      name: "03",
    },
    extraImage: {
      alt: "",
      view: "01",
      name: "03_extra",
    },
    subs: [
      {
        name: "Разработка программного обеспечения (ПО)",
        text:
          "Разработка, тестирование и отладка программ и программных средств системы." +
          "\n\n" +
          "Результаты разработки ПО оформляются в соответствии с требованиями ГОСТ 19.",
      },

      {
        name: "Разработка рабочей документации",
        sections: [
          {
            name: "Разработка пакета рабочей документации на АС",
            text:
              "Рабочая документация требуется для успешного развертывания, " +
              "ввода в действие и дальнейшей эксплуатации созданной системы.  " +
              "\n\n" +
              "Включает в себя необходимые руководства пользователей и персонала, " +
              "инструкции и описания, определенные в ТЗ. " +
              "Составной частью РД являются программа и методика испытаний.",
          },

          {
            name: "Разработка, согласование и утверждение программы и методики испытаний",
            text:
              "Программа и методика испытаний устанавливает технические данные, " +
              "которые подлежат проверке во время испытаний всей системы в целом или ее отдельных компонентов. " +
              "Документом определяется порядок проведения испытаний системы и оценки их результатов.",
          },
        ],
      },

      {
        name: "Разработка технической документации",
        text:
          "Разрабатывается и оформляется документация на проекты заказчика." +
          "\n\n" +
          "Заказчику предоставляется комплект документации на систему, " +
          "ее составные части и программное обеспечение, выполненный и оформленный " +
          "в соответствии с требованиями ГОСТ серий 34 и 19, прошедший нормоконтроль.",
      },
    ],
  },

  {
    name: "внедрение",
    image: {
      alt: "внедрение",
      view: "01",
      name: "04",
    },
    extraImage: {
      alt: "",
      view: "01",
      name: "04_extra",
    },
    subs: [
      {
        name: "Развертывание системы",
        text:
          "Осуществляются мероприятия по запуску системы: реализуются проектные решения, " +
          "проводятся наладки технических и программных средств, осуществляется загрузка данных." +
          "\n\n" +
          "Проводится общее тестирование системы, комплексная наладка ее средств." +
          "\n\n" +
          "Выполняется инструктаж эксплуатирующего персонала и " +
          "проводится проверка его способности обеспечить функционировании АС.",
      },

      {
        name: "Проведение испытаний",
        text:
          "Проведение комплекса последовательных испытаний, установленных программами и методиками испытаний. " +
          "Последовательно проводятся предварительные испытания, опытная эксплуатация и приемочные испытания. " +
          "\n\n" +
          "В результате определяется готовность персонала к работе в условиях функционирования АС, " +
          "фактическая эффективность АС, при необходимости проводится доработка АС и корректировка документации",
      },

      {
        name: "Проведение обучающих консультаций",
        text:
          "Составление графика консультаций, подготовка материалов, " +
          "разработка дистанционных обучающих курсов и проведение консультаций пользователей.",
      },
    ],
  },

  {
    name: "эксплуатация",
    image: {
      alt: "эксплуатация",
      view: "01",
      name: "05",
    },
    extraImage: {
      alt: "",
      view: "01",
      name: "05_extra",
    },
    subs: [
      {
        name: "Гарантийное обслуживание",
        text:
          "Гарантия – это период времени, в течение которого гарантируется бесперебойная работа АС, " +
          "в случае выхода из строя осуществляется бесплатное обслуживание, ремонт или замена." +
          "\n\n" +
          "Гарантийное обслуживание осуществляется в течение гарантийного срока, " +
          "указанного в формуляре (паспорте) на систему.",
      },

      {
        name: "Послегарантийное обслуживание",
        text:
          "Послегарантийное обслуживание предусматривает мероприятия, " +
          "аналогичные гарантийному обслуживанию системы, " +
          "проводящиеся по истечению гарантийного срока по отдельному договору.",
      },

      {
        name: "Техническая поддержка",
        text:
          "Организация процесса поддержки пользователей при эксплуатации АС. " +
          "Решение возникающих проблем, а также обеспечение пользователей " +
          "дополнительной информацией по интересующим вопросам.",
      },
    ],
  }
);
