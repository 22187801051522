
















import { Component, Vue } from "vue-property-decorator";
import TabsView from "@/components/Tabs/Tabs.vue";
import { tabs } from "@/data/services";
import { SMRoot } from "@/store";

@Component({
  components: {
    TabsView,
  },
})
export default class Services extends Vue {
  protected scrollTo(name: string) {
    SMRoot.scrollTo(name);
  }

  protected tabs = tabs;

  protected currentTabIndex = 0;

  protected changeSub(index: number) {
    this.tabs[this.currentTabIndex].currentSubIndex = index;
  }
}
